<template>
  <sign-page
    :title-text="typeMap[type] || '公文处理'"
    :request="request"
    table-size="large"
    :title-menus="[]"
    :tableActions="tableActions"
    @tableAction="tableAction"
    :column-list="columnList"
    simpleFilter="head"
    ref="docHandlePage"
    :table-actions-fixed="true"
    :tableActionsWidth="100">
    <div slot="title" style="display: flex;align-items: center;">
      <RadioGroup @on-change="$refs.docHandlePage.loadData()" type="button" v-model="status">
        <Radio label="wait">待处理</Radio>
        <Radio label="over">已处理</Radio>
        <Radio label="all">全部</Radio>
    </RadioGroup>
    </div>
    <fm-form-dialog
    form-title="处理时间修改"
    :open-dialog.sync="openDialog"
    :form-parms="formParms"
    :old-data="chooseData"
    :mask-closable="false"
    form-width="600px"
    @formSubmit="formSubmit"
    @handleClose="openDialog = false">
  </fm-form-dialog>
  </sign-page>
</template>

<script>
import DocTitle from './cmp/docTitle'

import {
  dateOperating
} from '@/fmlib'

import {
  officialDocumentHandleRequest as request
} from '../../api'

export default {
  created () {
    this.$store.dispatch('loadWorkerUserList')
  },
  activated () {
    this.$refs.docHandlePage.loadData()
  },
  props: {
    type: {
      type: String,
      default: 'doc'
    }
  },
  methods: {
    async formSubmit (data, resolve) {
      let parm = {
        handleTime: dateOperating.computeDay({days: 0, date: data.handleTime, format: 'yy-mm-dd hh:mm:ss'})
      }
      await request.updateHandleTime(this.chooseData.id, parm)
      resolve()
      this.openDialog = false
      this.$refs.docHandlePage.loadData()
    },
    async loadData () {
      let datas = await request.getMy({
        officialDocumentType: this.type
      })
      datas.forEach(v => {
        v.status = v.status === 'over' ? 'over' : (v.officialDocumentStatus === 'doing' ? 'wait' : 'gd')
      })
      if (this.status === 'all') {
        return datas
      } else {
        const status = this.status
        return datas.filter(v => v.status === status)
      }
    },
    async tableAction (parm) {
      this.chooseData = parm.data
      let action = parm.action
      if (action === 'detail') {
        this.$router.push({
          name: 'hrms.doc.detail',
          query: {
            id: this.chooseData.officialDocumentId,
            type: this.type,
            handle: 1
          }
        })
      } else if (action === 'updateHandleTime') {
        this.openDialog = true
      }
    }
  },
  computed: {
    formParms () {
      return [{
        type: 'datePicker',
        label: '处理时间',
        key: 'handleTime',
        check: {
          required: true
        }
      }]
    },
    tableActions () {
      return [{
        key: 'detail',
        label: '查看'
      },
      {
        key: 'updateHandleTime',
        label: '修改处理时间',
        show: (data) => {
          return data.status === 'over'
        }
      }]
    },
    columnList: {
      get () {
        let data = [{
          title: '',
          search: false,
          field: 'status',
          render: (h, rowData) => {
            return h('div', {
              domProps: {
                innerHTML: rowData && rowData.status === 'over' ? '已办' : (rowData && rowData.status === 'gd' ? '已归档未处理' :'待办')
              },
              style: {
                color: rowData.status === 'wait' ? '#F4628F' : null,
                'min-width': '40px'
              }
            })
          }
        },
        {
          title: '编码',
          sort: true,
          field: 'officialDocumentCode'
        },
        {
          title: '文件名称',
          sort: true,
          field: 'officialDocumentTitle',
          render: (h, row) => {
            return h(DocTitle, {
              props: {
                title: row.officialDocumentTitle
              }
            })
          }
        },
        {
          title: '备注',
          sort: true,
          field: 'remark',
          render: (h, row) => {
            return h(DocTitle, {
              props: {
                title: row.remark
              }
            })
          }
        },
        {
          title: '来文机关',
          sort: true,
          field: 'sendOrgName'
        },
        {
          title: '来文份数',
          sort: true,
          field: 'officialDocumentNum',
          dataType: Number
        },
        {
          title: '来文日期',
          sort: true,
          field: 'officialDocumentSendTime',
          dataType: Date,
          render: (h, rowData) => {
            return h('div', rowData && rowData.officialDocumentSendTime ? rowData.officialDocumentSendTime.slice(0, 10) : '-')
          }
        },
        {
          title: '来文号',
          sort: true,
          field: 'officialDocumentSendCode'
        },
        {
          title: '状态',
          sort: true,
          field: 'officialDocumentStatus',
          render: (h, rowData) => {
            return h('div', rowData && rowData.officialDocumentStatus === 'doing' ? '未结束' : '已结束')
          }
        },
        {
          title: '处理时间',
          sort: true,
          field: 'handleTime',
          dataType: Date,
          render: (h, rowData) => {
            return h('div', rowData && rowData.handleTime ? rowData.handleTime.slice(0, 10) : '-')
          }
        }]
        return data
      }
    }
  },
  data () {
    return {
      status: 'all',
      request: {
        get: this.loadData
      },
      openDialog: false,
      chooseData: null,
      modal: false,
      typeMap: {
        doc: '公文处理与归档',
        hywj: '会议与培训文件处理与归档',
        qtwh: '其他文件归档'
      }
    }
  }
}
</script>
